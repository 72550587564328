<template>
    <div class="content">
        <div class="top_bg" style="background-image: url('img/qixi/top_bg.png');">
            <div class="back" style="background-image: url('img/qixi/back.png');" @click="goBack"></div>
        </div>
        <div class="rule_text" style="background-image: url('img/qixi/rule_text.png');"></div>
        <div class="bottom_text" style="background-image: url('img/qixi/bottom_text.png');"></div>
    </div>
</template>
<script>
import "@/assets/css/qixi.css"
    export default{
        data(){
            return{

            }
        },
        created(){

        },
        methods:{
            goBack(){
                this.$router.go(-1)
            }
        }
    }
</script>
